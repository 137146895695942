<template>
<div>
  <h3 class="admin-title">Добро пожаловать в админ панель</h3>
  <h4 class="admin-subtitle">Статистика сайта</h4>
  <tricolor-line class="card_containet__tricolor"/>
  <form @submit.prevent="dataForm" class="data-block">
    <div>
      <p>С</p>
      <input placeholder="Введите дату" v-model="dateStart" type="date" name="dateStart" class="input blue">
    </div>
    <div>
      <p>По</p>
      <input placeholder="Введите дату" v-model="dateEng" type="date" name="dateEng" class="input blue">
    </div>
    <div>
      <p>Раздел</p>
      <select class="input blue" v-model="sectionFilter">
        <option :value="null">
          -
        </option>
        <option v-for="(section, index) in sections" :key="`sectionFilter${index}`" :value="section">
          {{ section }}
        </option>
      </select>
    </div>
    <input type="submit" class="btn-blue" value="Найти"/>
  </form>
  <table class="admin-table">
    <tr>
      <td>
        <b>Количество блогов</b>
      </td>
      <td>
        <b>{{ statistics.blogs.total }}</b>
      </td>
    </tr>

    <tr>
      <td>
        <b>Количество комментариев под блогами</b>
      </td>
      <td>
        <b>{{ statistics.blogs.total_comments }}</b>
      </td>
    </tr>

    <tr v-for="(item,i) in statistics.blogs.post_status" :key="`statistics.blogs.post_status${i + Math.random()}`">
      <td>
        Статус блога - {{ item.title }}
      </td>
      <td>
        {{ item.count }}
      </td>
    </tr>
  </table>
  <tricolor-line class="card_containet__tricolor"/>

  <table class="admin-table">
    <tr>
      <td>
        <b>Количество новостей</b>
      </td>
      <td>
        <b>{{ statistics.news.news_total }}</b>
      </td>
    </tr>
  </table>

  <article class="accordion-block" :class="{'active': showTag}">
      <div class="content-sb">
        <p class="accordion-title" @click="showTag = !showTag"><b>Количество новостей по тегам</b></p>
        <select v-model="newsTagFilter" class="input blue news-tag-filter">
          <option :value="null">
            -
          </option>
          <option :value="item.tag" v-for="item of statistics.news_tags" :key="`newsFilterTag${item.tag}`">
            {{ item.tag }}
          </option>
        </select>
        <p @click="showTag = !showTag"><b>{{ newsTags }}</b></p>
        <i class="accordion-block__details" :class="{'active':showTag}" @click="showTag = !showTag"></i>
      </div>
      <transition name="accordion">
        <table class="admin-table" v-show="showTag">
          <tr v-for="(item, i) of getNewsTags" :key="'sction'+i" class="accordion-block__body" v-show="item.count">
            <td>
              {{ item.tag }}
            </td>
            <td>
              {{ item.count }}
            </td>
          </tr>
        </table>
      </transition>
  </article>

  <article class="accordion-block" :class="{'active': show}">
      <div class="content-sb">
        <p class="accordion-title" @click="show = !show"><b>Количество новостей по разделам</b></p>
        <select v-model="newsSectionFilter" class="input blue news-tag-filter">
          <option :value="null">
            -
          </option>
          <option :value="item.tag" v-for="item of statistics.news_sections" :key="`newsFilterSection${item.tag}`">
            {{ item.tag }}
          </option>
        </select>
        <p @click="show = !show"><b>{{ newsSections }}</b></p>
        <i class="accordion-block__details" :class="{'active':show}" @click="show = !show"></i>
      </div>
      <transition name="accordion">

        <table class="admin-table" v-show="show">
          <tr v-for="(item, i) of getNewsSections" :key="'sction'+i"  class="accordion-block__body" v-show="item.count">
            <td>
              {{ item.tag }}
            </td>
            <td>
              {{ item.count }}
            </td>
          </tr>
        </table>
      </transition>
  </article>

  <article class="accordion-block" :class="{'active': showNews}">
    <div class="content-sb" @click="showNews = !showNews">
      <p class="accordion-title"><b>Количество новостей по рубрикам</b></p>
      <p><b>{{ statistics.news.news_total }}</b></p>
      <i class="accordion-block__details" :class="{'active':show}"></i>
    </div>
    <transition name="accordion">

      <table class="admin-table" v-show="showNews">
        <tr v-for="(item, i) of statistics.news.news_rubrics" :key="'sction'+i"  class="accordion-block__body">
          <td>
            {{ item.title }}
          </td>
          <td>
            {{ item.count }}
          </td>
        </tr>
      </table>
    </transition>
  </article>
<!--

  <table class="admin-table">
    <tr v-for="(item,i) in statistics.news.news_rubrics" :key="`statistics.blogs.news${i + Math.random()}`">
      <td>
        Новостная рубрика - {{ item.title }}
      </td>
      <td>
        {{ item.count }}
      </td>
    </tr>
  </table>
-->

  <tricolor-line class="card_containet__tricolor"/>

  <table class="admin-table">
    <tr>
      <td>
        Количество авторизированных пользователей
      </td>
      <td>
        {{ statistics.users.total }}
      </td>
    </tr>
  </table>
</div>
</template>

<script>
import TricolorLine from '@/components/TricolorLine'
import { queryBulder } from '@/methods/queryBulder'

export default {
  name: 'HelloPages',
  data () {
    return {
      statistics: [],
      dateStart: '',
      dateEng: '',
      newsSections: 0,
      newsTags: 0,
      show: false,
      showTag: false,
      showNews: false,
      newsTagFilter: null,
      newsSectionFilter: null,
      sectionFilter: null,
      sections: [
        'Актуальное',
        'Все новости',
        'Молния',
        'Проекты',
        'Общественные палаты регионов',
        'Общественная советы',
        'Общественная экспертиза',
        'Общественные наблюдательные комиссии'
      ]
    }
  },
  methods: {
    async getAdminStatistics () {
      let res = await fetch(`${this.$store.getters.getUrlApi}api/admin/dashboard`, {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      res = await res.json()
      this.statistics = await res.data
    },
    async dataForm () {
      const url = queryBulder(`${this.$store.getters.getUrlApi}api/admin/dashboard`, {
        dateStart: this.dateStart,
        dateEng: this.dateEng,
        tag: this.sectionFilter
      })
      let res = await fetch(url)
      res = await res.json()
      this.statistics = await res.data
    },
    async countNewsTags (list, count) {
      var tags = 0
      for (var item of this.statistics[list]) {
        tags += item.count
      }
      this[count] = await tags
    }
  },
  watch: {
    async statistics () {
      await this.countNewsTags('news_tags', 'newsTags')
      await this.countNewsTags('news_sections', 'newsSections')
    }
  },
  mounted () {
    this.getAdminStatistics()
  },

  computed: {
    getNewsTags () {
      return this.statistics.news_tags ? this.statistics.news_tags.filter((tag) => (this.newsTagFilter && tag.tag === this.newsTagFilter) || !this.newsTagFilter) : []
    },
    getNewsSections () {
      return this.statistics.news_sections ? this.statistics.news_sections.filter((tag) => (this.newsSectionFilter && tag.tag === this.newsSectionFilter) || !this.newsSectionFilter) : []
    }
  },

  components: {
    TricolorLine
  }
}
</script>

<style scoped lang="scss">
  .news-tag-filter {
    margin-right: 1rem;
  }

  .admin-table {
    border-spacing: 0 1rem;

    td {
      padding: 0;
      font-size: 1rem;
    }

    tr {

      > td:last-of-type {
        text-align: right;
      }
    }
  }

.content-sb {
  margin-bottom: 1.25rem;
}
.data-block{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 2rem;
  div {
    width: 20rem;
    margin-right: 2rem;
  }
}

.accordion-block {
    border: .06rem solid #E5E8EE;
    border-radius: 0.5rem;
    margin: 0 -.8rem 1.875rem;

    &.active {
      box-shadow: 0 .94rem 1.56rem rgba(189, 205, 221, 0.35);
    }
    > .content-sb{
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 0;
      padding: 0.75rem 0.8rem;
      cursor: pointer;
      p{
        padding-right: 1.5rem;
      }
      & .accordion-title{
        margin-right: auto;
      }
    }
      & i {
        border: .1875rem solid #9D9D9D;
        display: inline-block;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        width: .6rem;
        height: .6rem;
        border-top: 0;
        border-left: 0;
        margin-top: -0.2rem;
      }
      .active {
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
        margin-top: 0.3rem;
      }
    &__body{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.75rem 2.85rem 0.75rem 1.5rem;
    }
  }
</style>
